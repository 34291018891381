import './App.css';
import './coolicons.css';

const workImages1 = [ '1.sm.jpg', '10.sm.jpg', '4.sm.jpg', '6.sm.jpg', '3.sm.jpg' ]
const workImages2 = [ '2.sm.jpg', '9.sm.jpg', '5.sm.jpg', '8.sm.jpg', '7.sm.jpg' ]

function Separator() {
  return (
    <div>
      <hr
        className='border-[#1D1D1D] border my-16 mx-auto max-w-5xl'
      />
    </div>
  )
}

function App() {
  return (
    <div className='text-lg'>
      <div className='bg-[#131313] px-10 py-6 flex z-10'>
        <img
          src='/images/logo-transparent-64h.png'
          className='h-8 my-auto'
          alt='Site logo'
        />
        <div className='ml-auto my-auto flex space-x-4 text-2xl'>
          <a href='tel:+12568744118' className='block'>
            <i className='ci-phone text-green-500 hover:text-green-600 transition-colors' />
          </a>
          <a href='mailto:cjscustomboatworks@gmail.com' className='block'>
            <i className='ci-mail text-sky-500 hover:text-sky-600 transition-colors' />
          </a>
        </div>
      </div>
      <div className='px-10'>
        <div className='mx-auto max-w-4xl md:flex pb-48 md:pb-0 md:h-[70vh]'>
          <div className='mt-20 mr-6'>
            <h1 className='font-bold text-4xl'>CJ's Custom Boat Works, LLC</h1>
            <p className='text-[#cecece] mb-2'>We make the drive so you don't have to.</p>
            <ul className='text-[#cecece] list-disc ml-4'>
              <li>On-site installations</li>
              <li>Marine electronic and electrical installations & repairs</li>
              <li>Mobile Bay area</li>
            </ul>
            <h3 className='font-bold text-xl mt-6'>Contact</h3>
            <ul>
              <li>Jason Winkler, owner</li>
              <li>Phone: <a href='tel:+12568744118' className='text-[#3DA5FC] hover:underline'>(256) 874-4118</a></li>
              <li>Email: <a href='mailto:cjscustomboatworks@gmail.com' className='text-[#3DA5FC] hover:underline'>cjscustomboatworks@gmail.com</a></li>
            </ul>
          </div>
          <div className='rounded flex -mt-4 -z-10 overflow-y-hidden mx-auto'>
            <div className='mx-auto hidden md:block space-y-2'>
              {workImages1.map((filename) => {
                return (
                  <img
                    key={filename}
                    className='rounded w-48'
                    src={`/images/work/${filename}`}
                    alt=''
                  />
                )
              })}
            </div>
            <div className='mx-auto pl-2 hidden lg:block space-y-2'>
              {workImages2.map((filename) => {
                return (
                  <img
                    key={filename}
                    className='rounded w-48'
                    src={`/images/work/${filename}`}
                    alt=''
                  />
                )
              })}
            </div>
          </div>
          <div className='flex overflow-hidden w-full md:hidden mt-14 space-x-2 absolute left-0 scroll-smooth'>
            {[...workImages2, ...workImages1].map((filename) => (
              <img
                key={`horizontal-${filename}`}
                className='rounded h-36 transition-opacity'
                src={`/images/work/${filename}`}
                alt=''
              />
            ))}
          </div>
          <div className='bg-gradient-to-r from-black mt-14 left-0 absolute h-36 w-1/4 md:hidden'></div>
          <div className='bg-gradient-to-l from-black mt-14 right-0 absolute h-36 w-1/4 md:hidden'></div>
        </div>
        <Separator />
        <div className='mx-auto max-w-4xl'>
          <h1 className='font-bold text-4xl'>Service Area</h1>
          <div className='mt-4 md:flex'>
            <iframe
              title='Map of service area (Mobile Bay)'
              className='w-full md:w-4/6 md:mr-4 h-64 rounded'
              referrerPolicy='no-referrer-when-downgrade'
              src='https://www.google.com/maps/embed/v1/view?key=AIzaSyB3VtwdXgt5IVGeu2NYeRbI7r3giJR3g6I&center=30.6040937,-88.0741431&zoom=8'
              allowFullScreen
            />
            <p className='mt-4 md:w-2/6 md:mt-0 text-[#cecece]'>
              We will service your boat if it is the Mobile Bay area.
            </p>
          </div>
        </div>
        <Separator />
        <div className='mx-auto max-w-4xl sm:flex flex-wrap'>
          <div className='mx-auto'>
            <h1 className='font-bold text-4xl'>Services</h1>
            <ul className='mt-2 ml-4 text-[#cecece] list-disc'>
              <li>Fish finder installation</li>
              <li>Trolling motor installation</li>
              <li>Radar installation</li>
              <li>Solar panel installation</li>
              <li>Audio installation</li>
              <li>Lighting installation</li>
            </ul>
          </div>
          <div className='mx-auto mt-4 sm:mt-0'>
            <h2 className='font-bold text-4xl'>Serviced Brands</h2>
            <ul className='mt-2 ml-4 text-[#cecece] list-disc'>
              <li>Garmin</li>
              <li>Raymarine</li>
              <li>Lowrance</li>
              <li>Simrad</li>
              <li>Fusion</li>
              <li>JL Audio</li>
              <li>Standard Horizon</li>
              <li className='italic'>& more</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='mt-24 bg-[#131313] flex flex-wrap space-y-4 sm:space-y-0 px-16 py-8'>
        <img
          src='/images/logo-transparent-64h.png'
          className='h-16 mx-auto my-auto'
          alt='Site logo'
        />
        <ul className='mx-auto my-auto text-base text-[#cecece]'>
          <li>Jason Winkler, owner</li>
          <li>Phone: <a href='tel:+12568744118' className='text-[#3DA5FC] hover:underline'>(256) 874-4118</a></li>
          <li>Email: <a href='mailto:cjscustomboatworks@gmail.com' className='text-[#3DA5FC] hover:underline'>cjscustomboatworks@gmail.com</a></li>
        </ul>
      </div>
    </div>
  );
}

export default App;
